import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `https://back.base.org.ua/api/v1/`,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`
    }

    return config;
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export default axiosInstance;