import React, { useState } from 'react';
import { Form, Input, Button, Select, Radio, Table } from 'antd';

const { Option } = Select;

const Details = () => {
  const [form] = Form.useForm();
  const [type, setType] = useState('simplified');
  const [activity, setActivity] = useState('entrepreneur');

  const columns = [
    {
      title: 'Район',
      dataIndex: 'district',
      key: 'district',
    },
    {
      title: 'Отримувач',
      dataIndex: 'recipient',
      key: 'recipient',
    },
    {
      title: 'Дата оновлення',
      dataIndex: 'updateDate',
      key: 'updateDate',
    },
  ];

  const data = [
    {
      key: '1',
      district: 'Голосіївський р-н',
      recipient: 'ГУК у м.Києві (18050400)',
      updateDate: '1 січня 2022',
    },
  ];

  const handleGenerateInvoice = () => {
    form.validateFields().then(values => {
      console.log('Form values:', values);
    });
  };

  return (
    <div style={{ padding: 24 }}>
      <Form form={form} layout="vertical">
        {/* Місце реєстрації платника податків */}
        <Form.Item label="Місце реєстрації платника податків" name="location">
          <Select placeholder="Обрати місце">
            <Option value="kyiv">м.Київ (Голосіївський р-н)</Option>
            <Option value="kharkiv">Харків</Option>
            <Option value="lviv">Львів</Option>
            <Option value="dnipro">Дніпро</Option>
            <Option value="odesa">Одеса</Option>
          </Select>
        </Form.Item>

        {/* Тип господарської діяльності */}
        <Form.Item label="Тип господарської діяльності" name="activityType">
          <Radio.Group onChange={e => setActivity(e.target.value)} value={activity}>
            <Radio.Button value="entrepreneur">Підприємець</Radio.Button>
            <Radio.Button value="company">Компанія</Radio.Button>
          </Radio.Group>
        </Form.Item>

        {/* Система оподаткування */}
        <Form.Item label="Система оподаткування" name="taxSystem">
          <Radio.Group onChange={e => setType(e.target.value)} value={type}>
            <Radio.Button value="simplified">Спрощена система</Radio.Button>
            <Radio.Button value="general">Загальна система</Radio.Button>
          </Radio.Group>
        </Form.Item>

        {/* Таблиця реквізитів */}
        <Table columns={columns} dataSource={data} pagination={false} />

        {/* Кнопка для формування рахунку */}
        <Button type="primary" onClick={handleGenerateInvoice} style={{ marginTop: 16 }}>
          Сформувати рахунок
        </Button>
      </Form>
    </div>
  );
};

export default Details;
