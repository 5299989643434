import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Navigate } from 'react-router-dom';
import { useCheckAuthMutation } from 'services/AuthService';
import { useEffect, useState } from 'react';
import { setIsAuth } from 'features/auth/isAuthSlice';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const isAuth = useSelector((state: RootState) => state.isAuth.isAuth);
  const [checkAuth] = useCheckAuthMutation();
  const dispatch = useDispatch();
  
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  useEffect(() => {
    const authenticate = async () => {
      if (localStorage.getItem('access_token')) {
        try {
          const resp = await checkAuth({}).unwrap();
          if (resp.access_token) {
            localStorage.setItem('access_token', resp.access_token);
            dispatch(setIsAuth());
          }
        } catch (error) {
          console.error('Error during authentication:', error);
        }
      }
      setIsCheckingAuth(false); // Перевірка завершена
    };

    authenticate();
  }, [checkAuth, dispatch]);

  // Якщо перевірка автентифікації ще триває, можна показати спінер або інший індикатор
  if (isCheckingAuth) {
    return  <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'  
    }}>
      {isCheckingAuth && <Spin indicator={<LoadingOutlined spin />} size="large" />}
    </div>
  }

  // Якщо користувач не авторизований, перенаправляємо на сторінку входу
  if (!isAuth) {
    return <Navigate to="/sign-in" />;
  }

  // Повертаємо дочірні компоненти, якщо все ок
  return children;
};

export default ProtectedRoute;
